<template>
  <div
    class="bot-wrapper"
    v-show="(userRole !== 'RLC' && userRole !== 'FAC' && !userRole.startsWith('I')) || href === '/'"
  >
    <!-- :class="!showButton && 'hide'"> -->
    <!-- Toggle chat buton -->
    <button
      type="button"
      id="druid-toggle-chat-button"
      class="is-loading"
      arial-label="toggle chatbot"
    >
      <svg
        id="druid-toggle-chat-icon-desktop"
        class="--desktop"
        xmlns="http://www.w3.org/2000/svg"
        xml:space="preserve"
        width="52.913"
        height="71.811"
        style="
          shape-rendering: geometricPrecision;
          text-rendering: geometricPrecision;
          image-rendering: optimizeQuality;
          fill-rule: evenodd;
          clip-rule: evenodd;
        "
        viewBox="0 0 551.18 748.03"
      >
        <g id="Layer_x0020_1">
          <path style="fill: #f59100" d="M8.2 11.14h533.26v533.25H8.2z"/>
          <path
            class="fil1"
            d="M8.2 567.54h37.37v109.61l108.04-108.04 1.58-1.58h52.84C152.1 623.46 96.17 679.4 40.23 735.33c-3.39 3.46-8.11 5.6-13.34 5.6-10.32 0-18.69-8.37-18.69-18.69V567.52v.02z"
          />
          <path class="fil2" d="m144.06 544.39-78.04 78.04V517.7z"/>
          <path
            class="fil3"
            d="M202.98 381.37h9.41l-3.3 9.32zM346.67 381.37h-9.39l3.31 9.32z"
          />
          <path class="fil4" d="M209.08 351.61h131.5v168.1h-131.5z"/>
          <path
            class="fil2"
            d="M274.74 544.39H66.02v-53.7c0-15.66 9.54-28.98 24.37-34.02l82.37-28.01 12.71-16.56 89.32 89.2.04-.04.04.04 89.32-89.2 12.7 16.56 82.37 28.01c14.83 5.04 24.37 18.36 24.37 34.02v53.7H274.74z"
          />
          <ellipse
            class="fil4"
            transform="matrix(-.1287 .00915 .01252 .17609 169.847 274.108)"
            rx="129.68"
            ry="216.69"
          />
          <ellipse
            class="fil5"
            transform="matrix(-.10077 .00915 .0098 .17609 173.517 274.108)"
            rx="129.68"
            ry="216.69"
          />
          <ellipse
            class="fil5"
            cx="274.83"
            cy="272.77"
            rx="106.71"
            ry="126.72"
          />
          <path class="fil5" d="M183.38 183.92h182.9v56.63h-182.9z"/>
          <path
            class="fil1"
            d="m215.28 187.03-5.87 5.87-26.02 26.02a4.39 4.39 0 0 1-3.13 1.31c-2.42 0-4.39-1.96-4.39-4.38v-44.49l42.5 12.57-3.1 3.1h.01z"
          />
          <path
            class="fil1"
            d="m260.44 171.12-7.28 7.28-32.3 32.3a5.446 5.446 0 0 1-9.33-3.81v-39.62h52.75l-3.85 3.85h.01z"
          />
          <path
            class="fil1"
            d="m301.57 170.59-8.08 8.08-35.85 35.85a6.028 6.028 0 0 1-4.31 1.81c-3.34 0-6.04-2.7-6.04-6.04v-43.97h58.55l-4.27 4.27z"
          />
          <path
            class="fil6"
            d="m342.54 469.09 40.44-40.44-36.3-47.28-57.43 87.73V509.12c0 3.04 2.46 5.5 5.5 5.5 1.54 0 2.93-.63 3.92-1.65l32.63-32.63 7.35-7.35 3.89-3.89v-.01zM207.13 469.09l-40.44-40.44 36.3-47.28 57.43 87.73V509.12c0 3.04-2.46 5.5-5.5 5.5-1.54 0-2.93-.63-3.92-1.65l-32.63-32.63-7.35-7.35-3.89-3.89v-.01z"
          />
          <ellipse
            class="fil4"
            transform="matrix(.1287 .00915 -.01252 .17609 379.82 274.108)"
            rx="129.68"
            ry="216.69"
          />
          <ellipse
            class="fil5"
            transform="matrix(.10077 .00915 -.0098 .17609 376.15 274.108)"
            rx="129.68"
            ry="216.69"
          />
          <path
            class="fil7"
            d="m256.8 82.01-70.56 8.74c-11.97 1.48-21.14 10.46-22.88 22.4l-14.71 101.08c-1.24 8.52 1.37 16.38 7.46 22.47l20.8 20.8c.69.7 1.64 1.13 2.7 1.13 2.09 0 3.78-1.69 3.78-3.78v-70.94h175.56c4.04 0 7.33 3.3 7.33 7.33v63.6c0 2.09 1.69 3.78 3.78 3.78 1.06 0 2.01-.43 2.7-1.13l20.8-20.8c6.09-6.09 8.69-13.95 7.46-22.47l-14.71-101.08c-1.74-11.94-10.91-20.91-22.87-22.4L292.88 82c-12.59-1.56-23.49-1.56-36.08 0v.01z"
          />
          <ellipse class="fil8" cx="229.35" cy="261.63" rx="22.9" ry="11.31"/>
          <circle
            class="fil9"
            transform="matrix(.12717 .00652 -.00652 .12717 229.358 262.224)"
            r="73.46"
          />
          <circle
            class="fil7"
            transform="matrix(.08199 .0042 -.0042 .08199 229.358 262.224)"
            r="73.46"
          />
          <circle
            class="fil8"
            transform="matrix(.01454 .00075 -.00075 .01454 225.828 260.045)"
            r="73.46"
          />
          <path
            class="fil10"
            d="M229.37 250.32c12.65 0 22.9 5.07 22.9 11.31 0 .32-.03.63-.08.94-.97-4.91-10.82-8.77-22.82-8.77-12.01 0-21.85 3.86-22.83 8.77-.05-.31-.08-.63-.08-.94 0-6.25 10.25-11.31 22.91-11.31z"
          />
          <path
            class="fil7"
            d="M211.26 237.15h36.64c4.32 0 7.85 3.53 7.85 7.85v.84h-40.93c-3.09 0-5.75 1.1-7.93 3.28l-3.52 3.52c-.35.36-.84.58-1.39.58-1.07 0-1.94-.87-1.94-1.94v-2.92c0-6.17 5.04-11.21 11.21-11.21h.01z"
          />
          <ellipse class="fil8" cx="320.3" cy="261.63" rx="22.9" ry="11.31"/>
          <circle
            class="fil9"
            transform="matrix(-.12717 .00652 .00652 .12717 320.304 262.224)"
            r="73.46"
          />
          <circle
            class="fil7"
            transform="matrix(-.08199 .0042 .0042 .08199 320.304 262.224)"
            r="73.46"
          />
          <circle
            class="fil8"
            transform="matrix(-.01454 .00075 .00075 .01454 323.834 260.045)"
            r="73.46"
          />
          <path
            class="fil10"
            d="M320.3 250.32c-12.65 0-22.91 5.07-22.91 11.31 0 .32.03.63.08.94.97-4.91 10.82-8.77 22.82-8.77 12.01 0 21.85 3.86 22.83 8.77.05-.31.08-.63.08-.94 0-6.25-10.26-11.31-22.91-11.31h.01z"
          />
          <path
            class="fil7"
            d="M338.41 237.15h-36.64c-4.32 0-7.85 3.53-7.85 7.85v.84h40.93c3.08 0 5.75 1.1 7.93 3.28l3.51 3.52c.35.36.84.58 1.39.58 1.07 0 1.94-.87 1.94-1.94v-2.92c0-6.17-5.04-11.21-11.21-11.21zM315.98 341.25c-4.99 4.28-10.4 7.81-16.13 10.46l.37-.17c-4.3-1.45-8.74-2.55-13.3-3.25 10.69-1.96 20.59-6.47 29.17-12.94.35.33.65.74.86 1.22.74 1.63.33 3.56-.97 4.69v-.01zm-66.54 10.28c-5.59-2.63-10.87-6.09-15.76-10.28-1.31-1.12-1.71-3.05-.97-4.69.22-.48.52-.89.87-1.22 8.58 6.47 18.48 10.98 29.16 12.94-4.56.7-9 1.8-13.3 3.25z"
          />
          <path
            class="fil8"
            d="M233.57 335.34c.83-.78 1.98-1.1 3.11-.77 12.28 3.52 25.05 5.37 38.14 5.37s25.86-1.85 38.15-5.37c1.13-.32 2.28-.01 3.11.78-11.74 8.85-25.94 14.04-41.26 14.04-15.31 0-29.52-5.19-41.26-14.04l.01-.01z"
          />
          <path
            class="fil8"
            d="M300.22 351.53c-7.93 3.72-16.48 5.75-25.39 5.75s-17.46-2.03-25.39-5.75a79.185 79.185 0 0 1 25.39-4.18c8.84 0 17.36 1.46 25.39 4.18z"
          />
        </g>
      </svg>
      <span id="druid-toggle-chat-text" class="--desktop">
        <div class="druid-toggle-chat-text-inner">
          Ai nevoie de ajutor? <br/>
          Discuta cu Victor!
        </div>
      </span>
      <span id="druid-toggle-chat-text-2" class="--mobile">
        <svg
          xml:space="preserve"
          width="139.842"
          height="49.134"
          style="
            shape-rendering: geometricPrecision;
            text-rendering: geometricPrecision;
            image-rendering: optimizeQuality;
            fill-rule: evenodd;
            clip-rule: evenodd;
          "
          viewBox="0 0 1456.69 511.81"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            style="fill: #f59100"
            d="M1.65 316.05h1306.78v78.04l78.03-78.04h63.97V5.77H1.65z"
          />
          <path
            d="M1250.61 339.2h37.37v109.61l108.04-108.04 1.58-1.58h52.84c-55.93 55.93-111.86 111.87-167.8 167.8-3.39 3.46-8.11 5.6-13.34 5.6-10.32 0-18.69-8.37-18.69-18.69V339.18v.02z"
            style="fill: #1a1a1a; fill-rule: nonzero"
          />
          <text
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            style="
              font-style: normal;
              font-weight: 700;
              font-size: 141.067px;
              line-height: 1.25;
              font-family: inherit;
              fill: #fff;
              fill-opacity: 1;
              stroke: none;
              stroke-width: 3.52666;
            "
            x="95.852"
            y="212.099"
          >
            <tspan
              xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
              style="stroke-width: 3.52666; fill: #fff"
              x="145.852"
              y="200.099"
            >
              Discuta cu Victor!
            </tspan>
          </text>
        </svg>
      </span>
    </button>
  </div>
</template>

<script>
  import Cookies from "js-cookie";
  import druid_scripts from "@/assets/js/chat_utils.js";

  export default {
    name: "Chat",
    props: {
      href: {
        type: String
      },
      userRole: {
        type: String
      },
      companyId: {
        type: [Number, String]
      }
    },
    data() {
      return {
        queryParamsVar: false,
        showButton: false,
        botOptions: null,
        currentBot: null,
        currentBotOpts: null,
        currentBotName: null,
        currentBotToken: null,
        newBot: null,
        newBotToken: null,
        druidScriptIsLoaded: false,
        oldCompanyId: null
      };
    },
    methods: {
      isPastConversation(activity) {
        return (
          new Date(window.DruidWebchat.botConnection.serverTimestamp) >=
          new Date(activity.timestamp)
        );
      },
      isBCEvent(activity) {
        return (
          activity.type == "event" &&
          (!!!activity.channelData ||
            !activity.channelData.isActivityStatusUpdate) &&
          !this.isPastConversation(activity)
        );
      },
      afterInit() {
        // store current conversation token from cookie
        window.localStorage[
          this.currentBot.tokenProp
        ] = druid_scripts.druid_getToken();

        window.DruidWebchat.botConnection.activity$.subscribe(activity => {
          if (this.isBCEvent(activity) && !this.isPastConversation(activity)) {
            switch (activity.name.toLowerCase()) {
              case "switch_to_victor_dispecer":
                // debugger; ///
                this.newBot = this.botOptions["default"];
                this.newBotToken = window.localStorage[this.newBot.tokenProp];

                druid_scripts.druid_setActiveBot(this.newBot.name);
                druid_scripts.druid_setToken(this.newBotToken);
                // init chatbot
                window.DruidWebchat.init(this.newBot.opts).then(this.afterInit);
                this.currentBot = this.newBot;
                break;
              case "switch_to_victor":
                debugger; ///
                this.newBot = this.botOptions["victor"];
                this.newBotToken = window.localStorage[this.newBot.tokenProp];

                druid_scripts.druid_setActiveBot(this.newBot.name);
                druid_scripts.druid_setToken(this.newBotToken);

                // init chatbot
                window.DruidWebchat.init(this.newBot.opts).then(this.afterInit);
                this.currentBot = this.newBot;
                break;
              case "switch_to_victor_clienti":
                debugger; ///
                this.newBot = this.botOptions["victor-clienti"];
                this.newBotToken = window.localStorage[this.newBot.tokenProp];

                druid_scripts.druid_setActiveBot(this.newBot.name);
                druid_scripts.druid_setToken(this.newBotToken);

                // init chatbot
                window.DruidWebchat.init(this.newBot.opts).then(this.afterInit);
                this.currentBot = this.newBot;
                break;
              case "switch_to_victor_afiliati":
                debugger; ///
                this.newBot = this.botOptions["victor-afiliati"];
                this.newBotToken = window.localStorage[this.newBot.tokenProp];

                druid_scripts.druid_setActiveBot(this.newBot.name);
                druid_scripts.druid_setToken(this.newBotToken);

                // init chatbot
                window.DruidWebchat.init(this.newBot.opts).then(this.afterInit);
                this.currentBot = this.newBot;
                break;
              default:
                break;
            }
          }
        });
      },
      importDruidLibrary() {
        let druidChat = document.getElementById("script_druid");
        druidChat.addEventListener("load", () => {
          if (window.DruidWebchat) {
            this.druidScriptIsLoaded = true;
          }
        });
      },
      setBot(name) {
        this.currentBot = this.botOptions[name];
        this.currentBotOpts = this.botOptions[name].opts;
        druid_scripts.druid_deleteTokens(); // reset all conv tokens
        druid_scripts.druid_setExpDate(); // set expiration date for all bots
        druid_scripts.druid_setActiveBot(this.currentBot.name); // store active bot name (default)
        window.afterInit = this.afterInit;
        window.DruidWebchat.init(this.currentBot.opts).then(this.afterInit);
      }
    },
    created() {
      this.importDruidLibrary();
    },
    mounted() {
      // if (
      //     (Cookies.get("druidWebChatIsOpened") ||
      //       Cookies.get("druidWebChatIsOpened") === "true") &&
      //     this.$route.path !== "/chat_bot"
      //   ) {
      //     document.getElementById("druid-toggle-chat-button").click();
      //   }
     
      if (this.$route.path === "/" || this.$route.path === "/multibeneficii") {
        this.userRole = "";
        this.href = this.$route.path;
      }
      this.botOptions = {
        default: {
          opts: {
            botId: "f7d4464b-7962-4a2a-4e71-08d9f52f4532",
            baseUrl: "https://druidapi.druidplatform.com",
            // queryParams: ""
            UI: {
              containerElement: document.getElementById(
                "druid-toggle-chat-button"
              )
            }
          },
          tokenProp: "druid_token_default",
          name: "default"
        },
        victor: {
          opts: {
            botId: "bc3b8482-a2fc-43e5-7be4-08d9d0db1239",
            baseUrl: "https://druidapi.druidplatform.com",
            queryParams: "access_token=" + Cookies.get("token"),
            UI: {
              containerElement: document.getElementById(
                "druid-toggle-chat-button"
              ),
            }
          },
          tokenProp: "druid_token_victor",
          name: "victor"
        },
        "victor-mobile": {
          opts: {
            botId: "bc3b8482-a2fc-43e5-7be4-08d9d0db1239",
            baseUrl: "https://druidapi.druidplatform.com",
            queryParams: "access_token=" + Cookies.get("token"),
            UI: {
              containerElement: document.getElementById(
                "druid-toggle-chat-button"
              ),
              autoExpandEnabled: true,
              fullScreenMode: true,
            }
          },
          tokenProp: "druid_token_victor",
          name: "victor-mobile"
        },
        "victor-clienti": {
          opts: {
            botId: "647573b8-c910-4814-9a86-08d9f535c6cf",
            baseUrl: "https://druidapi.druidplatform.com",
            queryParams:
              "token=" + Cookies.get("token") + "&id=" + this.companyId,
            UI: {
              containerElement: document.getElementById(
                "druid-toggle-chat-button"
              )
            }
          },
          tokenProp: "druid_token_victor_clienti",
          name: "victor-clienti"
        },
        "victor-afiliati": {
          opts: {
            botId: "e12d6ef4-a13e-4b5e-002e-08da4eaf6386",
            baseUrl: "https://druidapi.druidplatform.com",
            queryParams:
              "token=" + Cookies.get("token") + "&id=" + this.companyId,
            UI: {
              containerElement: document.getElementById(
                "druid-toggle-chat-button"
              )
            }
          },
          tokenProp: "druid_token_victor_afiliati",
          name: "victor-afiliati"
        }
      };
      if (this.$route.name === "chat") {
        this.userRole = "MOB";
        this.href = "/chat_bot";
        this.setBot("victor-mobile");
      }
      setTimeout(() => {
        if (
          (!Cookies.get("druidWebChatIsOpened") ||
            Cookies.get("druidWebChatIsOpened") !== "true") &&
          this.$route.path === "/chat_bot"
        ) {
          document.getElementById("druid-toggle-chat-button").click();
        }
      }, 1000);

    },
    watch: {
      druidScriptIsLoaded: function () {
        if (!Cookies.get("token")) {
          if (
            !druid_scripts.druid_getExpDate() ||
            druid_scripts.druid_getExpDate() < Date.now() ||
            druid_scripts.druid_getToken() == "undefined" ||
            druid_scripts.druid_getToken() === "null"
          ) {
            this.currentBot = !this.currentBot
              ? this.botOptions["default"]
              : this.currentBot;
            this.currentBotOpts = !this.currentBotOpts
              ? this.botOptions.default.opts
              : this.currentBotOpts;
            druid_scripts.druid_deleteTokens(); // reset all conv tokens
            druid_scripts.druid_setExpDate(); // set expiration date for all bots
            druid_scripts.druid_setActiveBot(this.currentBot.name); // store active bot name (default)
          } else {
            this.currentBotName = druid_scripts.druid_getActiveBot();
            this.currentBot = druid_scripts.druid_getBotByName(
              this.botOptions,
              this.currentBotName
            );
            this.currentBotOpts = this.currentBot.opts;
            this.currentBotToken = window.localStorage[this.currentBot.tokenProp];
            druid_scripts.druid_setToken(this.currentBotToken);
          }

          window.afterInit = this.afterInit;

          window.DruidWebchat.init(this.currentBot.opts).then(this.afterInit);
        }
      },
      href: function () {
        if (this.href === "/" || this.href === "/multibeneficii") {
          this.userRole = "";
        }
        if (this.href === "/chat_bot") {
          this.userRole = "MOB";
        }
      },
      userRole: function () {
        if (this.userRole === "CST") {
          this.botOptions["victor-clienti"].opts.queryParams =
            "token=" + Cookies.get("token") + "&id=" + this.companyId;
          this.setBot("victor-clienti");
        } else if (this.userRole === "AFF") {
          this.botOptions["victor-afiliati"].opts.queryParams =
            "token=" + Cookies.get("token") + "&id=" + this.companyId;
          this.setBot("victor-afiliati");
        } else if (this.userRole === "BEN") {
          this.setBot("victor");
        } else if (this.userRole === "MOB") {
          this.setBot("victor-mobile");
        } else {
          this.setBot("default");
        }
        if (
          (this.userRole === "FAC" || this.userRole === 'RLC'
            || this.userRole === 'HLP' || this.userRole === 'AFA'
            || this.userRole === 'FLX'
          ) &&
          (Cookies.get("druidWebChatIsOpened") ||
            Cookies.get("druidWebChatIsOpened") === "true")
        ) {
          setTimeout(() => {
            document.getElementById("druid-toggle-chat-button").click();
          }, 500);
        }
      }
    }
  };
</script>
<style scoped>
  #druidContainerElement * {
    box-sizing: border-box;
  }

  #druidContainerElement {
    position: fixed;
    cursor: pointer;
    right: 40px;
    bottom: 40px;
    display: flex;
    align-items: center;
    color: #fff;
  }

  #druidContainerElementInner {
    background-color: #f59100;
    padding: 10px;
    flex-shrink: 0;
    border-radius: 5px;
  }

  #druidContainerTooltipOuter {
    position: relative;
    margin-right: 20px;
    border-radius: 5px;
  }

  #druidContainerTooltipOuter:after {
    content: "";
    display: block;
    height: 15px;
    width: 15px;
    position: absolute;
    right: 2px;
    top: 50%;
    transform: translate(50%, -50%) rotate(45deg);
    background-color: #f59100;
    border-radius: 2px;
  }

  #druidContainerTooltip {
    background-color: #f59100;
    min-height: 52px;
    border-radius: 5px;
    padding: 10px 15px;
    width: 160px;
  }

  .fil7 {
    fill: #1a1a1a;
  }

  .fil9 {
    fill: #4e5b59;
  }

  .fil2 {
    fill: #6698ba;
  }

  .fil3 {
    fill: #79a7c8;
  }

  .fil6 {
    fill: #91bad6;
  }

  .fil10 {
    fill: #c79777;
  }

  .fil4 {
    fill: #e8c7ac;
  }

  .fil5 {
    fill: #f9d9c0;
  }

  .fil8 {
    fill: #fff;
  }

  .fil1 {
    fill: #1a1a1a;
    fill-rule: nonzero;
  }

  /* Toggle Button Style BEGIN */

  #druid-toggle-chat-button {
    position: fixed;
    border: none;
    bottom: 0;
    right: 0;
    margin: 0 20px 25px 0;
    background-color: transparent;
    display: flex;
    align-items: stretch;
    justify-content: center;
    padding: 2px;
    cursor: pointer;
    display: flex;
  }

  #druid-toggle-chat-icon-desktop {
    margin-bottom: -20px;
    position: relative;
    top: -1px;
    width: 45px;
  }

  #druid-toggle-chat-text {
    display: flex;
    padding: 10px 15px 7px;
    align-items: center;
    text-align: left;
    order: -1;
    margin-right: 8px;
    color: #fff;
    background-color: #f59100;
    font-size: 12px;
    font-weight: 500;
  }

  #druid-toggle-chat-text-2 {
    width: 140px;
    height: 50px;
    display: block;
  }

  @media (max-width: 768px) {
    #druid-toggle-chat-button .--desktop {
      display: none;
    }
  }

  @media (min-width: 769px) {
    #druid-toggle-chat-button .--mobile {
      display: none;
    }
  }
</style>
<style>
  #botDiv {
    z-index: 9999999999 !important;
  }
</style>
