export default [
  {
    Companies: [
      {
        fileName: "login/companii/up-cadou-paste.png",
        link: "https://upromania.ro/companii/card-cadou/",
        category: "Banner",
        action: "Click",
        label: "Up_cadou_campanie",
      },
      {
        fileName: "login/companii/up-cultura-up-onlline.png",
        link:
        "https://upromania.ro/companii/card-cultural/",
        category: "Banner",
        action: "Click",
        label: "Companii_cultura_VN220",
      },
      {
        fileName: "login/companii/up-vacanta-up-online.png",
        link: "https://catalogulcuvacante.ro/b2c/#/up/catalogs",
        category: "Banner",
        action: "Click",
        label: "Companii_ConfigVacante",
      },
      {
        fileName: "login/companii/banner_recomanda.png",
        link:
          "https://upromania.ro/recomanda/?utm_source=uponline&utm_medium=banner&utm_campaign=Recomanda",
        category: "Banner",
        action: "Click",
        label: "Companii_Recomanda",
      },
    ],
    Beneficiaries: [
      {
        fileName: "login/utilizatori/banner_mobilplus.png",
        link:
          "https://upromania.page.link/ump",
        category: "Banner",
        action: "Click",
        label: "Utilizatori_mobilplus",
      },
      {
        fileName: "login/utilizatori/banner_blenche.png",
        link:
          "https://www.youtube.com/watch?v=Yeo8EFuJ0TM",
        category: "Banner",
        action: "Click",
        label: "Utilizatori_blenche",
      },
      {
        fileName: "login/utilizatori/banner_sezamo.png",
        link:
          "",
        category: "Banner",
        action: "Click",
        label: "Utilizatori_sezamo",
      }, 
      {
        fileName: "login/companii/banner_recomanda.png",
        link: "https://upromania.ro/recomanda/",
        category: "Banner",
        action: "Click",
        label: "UtilizatoriRecomandaCastiga",
      },
    ],
  },
];
